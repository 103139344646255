import React, { Component } from "react"
import Slider from "react-slick"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
// import GalleryLightbox from "./GalleryLightbox"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons"

const SliderStyled = styled(Slider)`
    padding: 0 0rem;

    .slider-arrow {
        left: 0;

        &.next {
            left: auto;
            right: 0;
        }
    }
`

const Slide = styled.button`
    border: 0;
    padding: 0;
    background-color: transparent;
`

class GallerySlider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            modalOpen: false,
            activeImage: 0
        }

        this.toggleModal = this.toggleModal.bind(this)
    }

    toggleModal() {
        this.setState({
            modalOpen: !this.state.modalOpen
        })
    }

    render() {
        const { images } = this.props

        function PrevButton({ onClick }) {
            return(
                <button onClick={onClick} className="slider-arrow prev" aria-label="Previous">
                    <FontAwesomeIcon icon={faAngleLeft} />
                </button>
            ) 
        }

        function NextButton({ onClick }) {
            return (
                <button onClick={onClick} className="slider-arrow next" aria-label="Next">
                    <FontAwesomeIcon icon={faAngleRight} />
                </button>
            )
        }

        //slider settings
        const settings = {
            dots: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            infinite: false,
            lazyload: true,
            prevArrow: <PrevButton/>,
            nextArrow: <NextButton/>,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
            ]
        }

        const lightboxOptions = {
            buttons: {
                showDownloadButton: false,
                showAutoplayButton: false,
                showFullscreenButton: false
            }
        } 

        const sliderImages = images.map((image, i) => {
            return(
                <Slide key={i} className="px-1">
                    <a href={image.localFile.publicURL}>
                        <GatsbyImage image={image.localFile.resize.gatsbyImageData} alt={image.altText} />
                    </a>
                </Slide>
            )
        })

        return(
            <SimpleReactLightbox>
                <SRLWrapper options={lightboxOptions}>
                    <SliderStyled {...settings}>
                        {sliderImages}
                    </SliderStyled>
                </SRLWrapper>

                {/* <GalleryLightbox 
                    modalOpen={this.state.modalOpen} 
                    toggleModal={this.toggleModal} 
                    images={images} 
                    activeImage={this.state.activeImage}
                /> */}
            </SimpleReactLightbox>
        )
    }
}

export default GallerySlider