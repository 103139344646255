import React from "react"
import styled from "styled-components"
import { Container } from "reactstrap"
import { GatsbyImage } from "gatsby-plugin-image"

const ImageFigure = styled.figure`
    width: 100%;
    margin: 0;
`

const ImageBlock = (props) => {
    return (
        <Container className="pb-3" fluid>
            <ImageFigure>
                {props.data.image &&
                    <GatsbyImage 
                        image={props.data.image.localFile.childImageSharp.gatsbyImageData} 
                        alt={props.data.image.altText} 
                    />    
                }
                {props.data.image && props.data.image.caption &&
                    <figcaption dangerouslySetInnerHTML={{ __html: props.data.image.caption }} />
                }
            </ImageFigure>
        </Container>
    )
}

export default ImageBlock



