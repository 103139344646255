import React, { Component } from "react"
import TextBlock from "./FeaturedBlocks/TextBlock"
import ImageBlock from "./FeaturedBlocks/ImageBlock"
import VideoBlock from "./FeaturedBlocks/VideoBlock"
import FaqBlock from "./FeaturedBlocks/FaqBlock"
import GeneralTable from "./FeaturedBlocks/GeneralTable"
import ImageGrid from "./FeaturedBlocks/ImageGrid"
import ColumnedImageWithText from "./Blocks/ColumnedImageWithText"
import HorizontalImageText from "./Blocks/HorizontalImageText"
import GalleryCarousel from "./FeaturedBlocks/GalleryCarousel"
import TextWithPopupButton from "./FeaturedBlocks/TextWithPopupButton"
import GalleryMasonry from "./FeaturedBlocks/GalleryMasonry"
import Animate from "../shared/Animate"

class FlexibleContentFeatured extends Component  {

    render() {
        let blocks = ""

        if (this.props.blocks) {
            blocks = this.props.blocks.map((block, i) => {
                // console.log(block.__typename)
                // Listing all post types as used on all, need to figure out how to merge into one graphql node
                switch (block.__typename) {
                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_TextBlock':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_TextBlock':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_TextBlock':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_TextBlock':
                        return (
                            <Animate>
                                <TextBlock data={block} key={i} />
                            </Animate>
                        )

                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_ImageBlock':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_ImageBlock':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_ImageBlock':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_ImageBlock':
                        return (
                            <Animate>
                                <ImageBlock data={block} key={i} />
                            </Animate>
                        )

                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_VideoBlock':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_VideoBlock':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_VideoBlock':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_VideoBlock':
                        return (
                            <Animate>
                                <VideoBlock data={block} key={i} />
                            </Animate>
                        )

                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_FaqBlock':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_FaqBlock':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_FaqBlock':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_FaqBlock':
                        return (
                            <Animate>
                                <FaqBlock data={block} key={i} />
                            </Animate>
                        )
      
                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_GeneralTable':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_GeneralTable':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_GeneralTable':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_GeneralTable':
                        return (
                            <Animate>
                                <GeneralTable data={block} key={i} />
                            </Animate>
                        )
     
                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_ImageGrid':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_ImageGrid':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_ImageGrid':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_ImageGrid':
                        return (
                            <Animate>
                                <ImageGrid data={block} key={i} />
                            </Animate>
                        )

                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_ColumnedImageWithText':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_ColumnedImageWithText':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_ColumnedImageWithText':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_ColumnedImageWithText':
                        return (
                            <Animate>
                                <ColumnedImageWithText block={block} padding={false} template="featured" key={i} />
                            </Animate>
                        )

                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_HorizontalImageWithText':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_HorizontalImageWithText':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_HorizontalImageWithText':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_HorizontalImageWithText':
                        return (
                            <Animate>
                                <HorizontalImageText block={block} key={i} />
                            </Animate>
                        )
  
                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_GalleryCarousel':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_GalleryCarousel':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_GalleryCarousel':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_GalleryCarousel':
                        return (
                            <Animate>
                                <GalleryCarousel block={block} key={i} />
                            </Animate>
                        )
     
                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_TextWithPopupButton':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_TextWithPopupButton':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_TextWithPopupButton':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_TextWithPopupButton':
                        return (
                            <Animate>
                                <TextWithPopupButton block={block} key={i} />
                            </Animate>
                        )

                    case 'WpPage_Acftwocolumnflexiblecontent_TwoColumnBlocks_GalleryMasonry':
                    case 'WpTrail_Acftwocolumnflexiblecontent_TwoColumnBlocks_GalleryMasonry':
                    case 'WpThingtodo_Acftwocolumnflexiblecontent_TwoColumnBlocks_GalleryMasonry':
                    case 'WpNewsArticle_Acftwocolumnflexiblecontent_TwoColumnBlocks_GalleryMasonry':
                        return (
                            <Animate>
                                <GalleryMasonry block={block} key={i} />
                            </Animate>
                        )
                        
                    default:
                        return null
                }
            })

        }

        return blocks
    }
} 

export default FlexibleContentFeatured